import React from 'react';

import featuredImage from './images/featured-image-data-engineering.jpg';
import Equinix from '@images/logos-partners/equinix-logo.svg';
import contactButtonImageSectionImage from '@commons/contact-button-image-section/images/image3.svg';
import {
  googleCloud,
  aws,
  azure,
  ibmCloud,
  hadoop,
  apacheSpark,
  apacheHive,
  amazonRedshift,
  snowflake,
  sas,
  kafka,
  rabbitMQ,
  amazonKinesis,
  amazonSQS,
  redis,
  mongoDB,
  cassandra,
  etcd,
  timescale,
  postgreSQL,
  mySQL,
  mariaDB,
  microsoftSQLServer,
  oracle,
  apacheAirflow,
  kibana,
  elasticsearch,
} from '@images/logos-techstack';

import { paths } from '@src/constants/pathsEnum';

import * as styles from './payload.module.scss';

export const dataEngineeringSEOProps = {
  title: 'Data Engineering Services & Expertise - CodiLime',
  description:
    'Find out how our data engineers can help you manage the data within your organization and turn it into insights that will get you ahead on the market.',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: (
    <>
      Data
      <br /> engineering
      <br /> services
    </>
  ),
  isBorder: false,
};

export const sectionUnderHeaderProps = {
  contactButtonLabel: 'Employ your data',
  textPartOne: (
    <>
      Every second a vast amount of data is being generated by users, machines, and software. Data engineering and data
      science makes it possible to process, store
    </>
  ),
  textPartTwo: (
    <>
      {' '}
      and analyze all this data to provide information useful for business decisions. Find out how our data engineers
      can help you manage the data within your organization and turn it into knowledge and insights that will get you
      ahead on the market.
    </>
  ),
};

export const blocksRedirectingToResourcesProps = {
  sectionProps: {
    title: 'Our publications about data engineering',
    subtitle:
      'Data engineering is a broad topic to cover on one page. If you want to learn more, check out our experts’ publications.',
    id: 'publications',
    classNames: {
      titlesContainer: styles.titlesContainer,
    },
  },
  blocks: [
    {
      text: 'Big data. What is it and why is it so important?',
      link: '/blog/big-data/',
    },
    {
      text: 'SQL vs NoSQL. The evolution of relational and non-relational databases',
      link: '/blog/sql-vs-nosql/',
    },
    {
      text: 'Top 20 data engineering tools',
      link: '/blog/top-data-enigneering-tools/',
    },
    {
      text: 'ETL vs ELT. What are they and when do we need them?\n',
      link: '/blog/etl-vs-elt/',
    },
  ],
};

export const blocksChangingBackgroundColorOnHoverProps = {
  title: 'Data engineering services - our approach',
  subtitle:
    'Just like our partners, we value transparency, flexibility, and valid expertise. Here you can find the principles we will focus on while creating a data engineering solution that boosts your business.',
  classNames: { innerWrapper: styles.innerWrapperCommon },
};

export const testimonialProps = {
  testimonial: {
    quote: `CodiLime’s software engineers have been a great addition to our team by bringing a unique combination of experience in Go and network domain knowledge. Apart from the code contributions & reviews, they have provided invaluable support and guidance to the team with technical decisions. Their feedback and insights have positively upleveled the quality and reliability of the software we’re building. We’re impressed with their professionalism, engagement, and the value they bring to day-to-day cooperation.`,
    author: 'Kristine Antons - Senior Software Engineering Manager',
    logo: Equinix,
    logoAlt: 'Equinix',
  },
  classNames: {
    innerWrapper: styles.innerWrapperTestimonials,
  },
};

export const sliderWithClickableBlocksProps = {
  sectionProps: {
    title: 'Models of data engineering services',
    subtitle:
      'Every business has different needs and expectations regarding data engineering. We understand that, so we want to provide you with a choice of several cooperation models to ensure a flexible partnership. ',
  },
  classNames: {
    section: styles.sliderWithClickableBlocksSection,
  },
};

export const techStackWithCategoriesProps = {
  sectionProps: {
    title: 'Data engineering technology stack',
    subtitle:
      'We use various technologies to provide exceptional data engineering services. We have experience in using SQL and NoSQL databases, cloud services, and other data engineering tools.',
    id: 'technology-stack',
  },
  classNames: {
    innerWrapper: styles.innerWrapperCommon,
  },
  blocksForFirstColumn: [
    {
      categoryTitle: 'Cloud-native data processing',
      technologiesData: [googleCloud, aws, azure, ibmCloud],
    },
    {
      categoryTitle: 'Apache data stack',
      technologiesData: [hadoop, apacheSpark, apacheHive],
    },
    {
      categoryTitle: 'Elastic Stack',
      technologiesData: [elasticsearch, kibana],
    },
    {
      categoryTitle: 'Data warehousing tools',
      technologiesData: [amazonRedshift, snowflake, sas],
    },
    {
      categoryTitle: 'Queuing solutions',
      technologiesData: [kafka, rabbitMQ, amazonKinesis, amazonSQS],
    },
  ],
  blocksForSecondColumn: [
    {
      categoryTitle: 'Caching mechanisms',
      technologiesData: [redis],
    },
    {
      categoryTitle: 'NoSQL solutions',
      technologiesData: [mongoDB, cassandra, etcd, timescale],
    },
    {
      categoryTitle: 'Relational databases',
      technologiesData: [postgreSQL, mySQL, mariaDB, microsoftSQLServer],
    },
    {
      categoryTitle: 'Data management tools',
      technologiesData: [oracle, apacheAirflow],
    },
  ],
};
export const flipCardsSectionProps = {
  sectionProps: {
    title: 'Our expertise in practice',
    subtitle:
      'At CodiLime, we use our expertise to help our partners explore one of their most valuable assets - their data. We created scalable solutions that make storing, processing, and analyzing data a breeze.',
    classNames: {
      subtitleStyles: styles.sectionDescription,
    },
  },
  isFlippable: true,
  flipCardsData: [
    {
      title: 'Managing rapid increase of processed data',
      description: (
        <>
          With our help, our clients are able to manage a rapid increase of data in their organizations. We have
          provided tools to handle <b>occasional peaks in day-to-day data processing</b> prepared{' '}
          <b>cost-saving and scalability</b> on-demand scenarios for batch processing (i.e. weekly, monthly).
        </>
      ),
      color: '#041C2E',
      paddingTop: '92px',
    },
    {
      title: 'Designing a monitoring and data analytics portal',
      description: (
        <>
          We have created an integrated <b>data-gathering platform</b> for application and network state monitoring. The
          platform ensured <b>data normalization and implementation of a single data sink</b>. The portal was
          domain-specific and designed with the client’s requirements in mind.
        </>
      ),
      color: '#053356',
      paddingTop: '97px',
    },
    {
      title: 'Fixing scalability issues',
      description: (
        <>
          Scalability issues are a frequent occurrence in applications based on a relational database. We have provided{' '}
          <b>solutions and optimizations</b> to help solve this issue and allow the client to deal with the challenges
          of increased amounts of data.{' '}
        </>
      ),
      color: '#122B3F',
      paddingTop: '105px',
    },
    {
      title: 'Creating UX & UI',
      description: (
        <>
          We have designed a <b>useful and intuitive portal interface</b> for building and monitoring ETL data flows.{' '}
        </>
      ),
      color: '#053356',
      paddingTop: '92px',
    },
    {
      title: 'Building a QA and testing platform',
      description: (
        <>
          We have provided clients with an integrated <b>testing and quality assurance platform</b> for data processing.
          The tool included scheduled and ad-hoc runs of dozens of testing suites, executed according to specific
          changes in the system. Users were able to manage the platform and monitor the outcomes using a provided
          graphical tool.
        </>
      ),
      color: '#021A2D',
      paddingTop: '92px',
    },
  ],
};

export const coloredBlocksWithRedirectsProps = {
  title: 'Explore our other services',
  subtitle:
    'Data engineering is only one area of our expertise. At CodiLime, we can help you with much more - from building MVPs to creating reliable network solutions. Discover more of our services:',
  blocks: [
    {
      text: (
        <>
          <span>Custom software </span>
          <span>development</span>
        </>
      ),
      link: paths.SERVICES.CUSTOM_DEVELOPMENT,
    },
    {
      text: (
        <>
          <span>Network Professional </span>
          <span>Services</span>
        </>
      ),
      link: paths.SERVICES.NETWORK_PROFESSIONAL_SERVICES,
    },
  ],
  classNames: {
    firstBlock: styles.coloredBlocksFirstBlock,
    secondBlock: styles.coloredBlocksSecondBlock,
    rightPart: styles.coloredBlocksRightPart,
    innerWrapper: styles.coloredBlocksInnerWrapper,
  },
};

export const blocksInRowsWithOneActiveProps = {
  title: 'How our data engineers will help you',
  subtitle:
    'Data engineering and data science are complex processes that consist of various stages. The services we perform to create functional data engineering solutions cover them all - from an early audit to the final touches, like UX design or technology recommendations.',
  blocks: [
    {
      title: 'Data processing flows',
      description: (
        <>
          We design <b>scalable data processing</b> flows with all needed normalizations and transformations, and
          implement them. We provide both cloud and on-prem data processing.
        </>
      ),
    },
    {
      title: 'Database case analysis',
      description: (
        <>
          We thoroughly analyze a targeted use case, both SQL and NoSQL, to identify room for improvement and{' '}
          <b>provide recommendations.</b> We also implement suitable solutions.
        </>
      ),
    },
    {
      title: 'Performance tuning',
      description: (
        <>
          We perform an audit of your current database and present <b>recommendations for optimization</b> from both
          performance and cost perspectives. We build <b>customized caching solutions</b> that will improve the
          efficiency of your database.
        </>
      ),
    },
    {
      title: 'Integration mechanisms',
      description: (
        <>
          We design <b>high-performance, message-based integration mechanisms</b> for customer applications. They are
          especially useful in business and customer data and streamline data transformation.
        </>
      ),
    },
    {
      title: 'UX design',
      description: (
        <>
          Our designers provide a <b>user-friendly design for data-related products</b> to ensure a positive user
          experience. Usable and intuitive interfaces increase efficiency and allow for acquiring insights more quickly.
        </>
      ),
    },
    {
      title: 'Data validation layers, analytics and recommendations',
      description: (
        <>
          We analyze your <b>data validation layers & tollgates</b> and recommend the ones that fit your expectations.
          We also integrate them with your CI/CD process to ensure a smooth workflow.
        </>
      ),
    },
  ],
  classNames: {
    section: styles.section,
  },
};

export const opacityScrolledSliderProps = {
  title: 'How our data engineering services can benefit your business',
  textBlocks: [
    <>
      <span>Scalable solutions</span> able to store and process large amounts of data
    </>,
    <>
      <span>Increased data processing speed</span> and easier access to insights
    </>,
    <>
      Better-informed decisions thanks to <span>data-based predictions</span>
    </>,
    <>
      <span>Improved product quality</span> and customer satisfaction
    </>,
    <>
      Prediction of market changes and discovery of <span>new possibilities for business development</span>
    </>,
    <>
      Increasing <span>cost efficiency</span> by optimizing data architecture
    </>,
  ],
};

export const contactButtonImageSectionProps = {
  sectionProps: {
    classNames: {
      outerWrapper: styles.contactButtonImageSectionOuterWrapper,
      innerWrapper: styles.contactButtonImageSectionInnerWrapper,
      section: styles.contactButtonImageSection,
    },
  },
  title: <>Explore the knowledge within your data</>,
  image: contactButtonImageSectionImage,
  imageAlt: 'alt="Get in touch and discover the knowledge within your data',
  isH2: true,
  buttonTitle: 'Contact us',
  classNames: {
    customContent: styles.contactButtonImageSectionCustomContent,
    customContainer: styles.contactButtonImageSectionCustomContainer,
    imageContainer: styles.contactButtonImageSectionImageContainer,
  },
};

export const bannerWithImageProps = {
  title: 'Make your data work for you with AI/ML solutions',
  classNames: { titlesContainer: styles.bannerWithImageTitlesContainer },
};
