import React from 'react';

import Layout from '@src/layouts';

import {
  SectionUnderHeaderWithUnderline,
  ContactButtonImageSection,
  BlocksRedirectingToResources,
  BlocksChangingBackgroundColorOnHover,
  MobileHeaderLayoutB,
  DesktopHeaderLayoutB,
  MeetCodilimeSection,
  NewClientSingleTestimonial,
  SliderWithClickableBlocks,
  FlipCardsSection,
  BlocksInRowsWithOneActive,
  TechStackWithCategories,
  ColoredBlocksWithRedirects,
  OpacityScrolledSlider,
  BannerWithImage,
} from '@commons/index';

import SEO from '@commons/SEO';

import {
  dataEngineeringSEOProps,
  headerProps,
  sectionUnderHeaderProps,
  blocksRedirectingToResourcesProps,
  blocksChangingBackgroundColorOnHoverProps,
  coloredBlocksWithRedirectsProps,
  testimonialProps,
  sliderWithClickableBlocksProps,
  flipCardsSectionProps,
  blocksInRowsWithOneActiveProps,
  techStackWithCategoriesProps,
  opacityScrolledSliderProps,
  contactButtonImageSectionProps,
  bannerWithImageProps,
} from '@pages-impl/data-engineering/payload';

export default function DataEngineering(props) {
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Make your data truly valuable"
      {...props}
    >
      <SEO {...dataEngineeringSEOProps} />
      <DesktopHeaderLayoutB {...headerProps} />
      <MobileHeaderLayoutB {...headerProps} />
      <SectionUnderHeaderWithUnderline {...sectionUnderHeaderProps} />
      <BlocksInRowsWithOneActive {...blocksInRowsWithOneActiveProps} />
      <ContactButtonImageSection {...contactButtonImageSectionProps} />
      <FlipCardsSection {...flipCardsSectionProps} />
      <BannerWithImage {...bannerWithImageProps} />
      <OpacityScrolledSlider {...opacityScrolledSliderProps} />
      <TechStackWithCategories {...techStackWithCategoriesProps} />
      <BlocksChangingBackgroundColorOnHover {...blocksChangingBackgroundColorOnHoverProps} />
      <BlocksRedirectingToResources {...blocksRedirectingToResourcesProps} />
      <NewClientSingleTestimonial {...testimonialProps} />
      <SliderWithClickableBlocks {...sliderWithClickableBlocksProps} />
      <MeetCodilimeSection />
      <ColoredBlocksWithRedirects {...coloredBlocksWithRedirectsProps} />
    </Layout>
  );
}
