// extracted by mini-css-extract-plugin
export var bannerWithImageTitlesContainer = "payload-module--banner-with-image-titles-container--42dcf";
export var coloredBlocksFirstBlock = "payload-module--colored-blocks-first-block--de671";
export var coloredBlocksInnerWrapper = "payload-module--colored-blocks-inner-wrapper--f54de";
export var coloredBlocksRightPart = "payload-module--colored-blocks-right-part--4f79b";
export var coloredBlocksSecondBlock = "payload-module--colored-blocks-second-block--b3426";
export var contactButtonImageSection = "payload-module--contact-button-image-section--70fdf";
export var contactButtonImageSectionCustomContainer = "payload-module--contact-button-image-section-custom-container--0eb3e";
export var contactButtonImageSectionCustomContent = "payload-module--contact-button-image-section-custom-content--02a92";
export var contactButtonImageSectionImageContainer = "payload-module--contact-button-image-section-image-container--300b7";
export var contactButtonImageSectionInnerWrapper = "payload-module--contact-button-image-section-inner-wrapper--33e3a";
export var contactButtonImageSectionOuterWrapper = "payload-module--contact-button-image-section-outer-wrapper--4476e";
export var innerWrapperCommon = "payload-module--inner-wrapper-common--646dd";
export var innerWrapperTestimonials = "payload-module--inner-wrapper-testimonials--2e835";
export var section = "payload-module--section--1db90";
export var sectionDescription = "payload-module--section-description--ca3b2";
export var sliderWithClickableBlocksSection = "payload-module--slider-with-clickable-blocks-section--b0228";
export var titleBorderWidth = "payload-module--title-border-width--87e60";
export var titlesContainer = "payload-module--titles-container--a448e";